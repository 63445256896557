<template>
  <!-- 중간영역 -->
  <div id="container">
    <!--메인비주얼-->
    <div class="main-top__wrap">
      <div class="main-top">
        <div class="main-top__left">
          <p class="main-top__desc">{{$t('message.homepageMain')}}</p>
          <div class="main-top__title">
            <h3>{{$t('koagi')}}</h3>

            <div class="main-top-slider__control">
              <div class="prev">
                <button  type="button" @click="togglePrev()" >이전슬라이드</button>
              </div>
              <div :class="mainPauseClass">
                <button  type="button" @click="togglePausePlay()" >재생/일시중지</button>
              </div>
              <div class="next">
                <button  type="button" @click="toggleNext()" >다음슬라이드</button>
              </div>
            </div>
          </div>
        </div>
        <div class="main-top__right">
          <div :class="locationMapClass">
            <span type="button" class="location-map__btn" @click="toggleLocationMap()">
              <img src="@/assets/img/main2021/ico-pin_line.png" :alt="$t('organLocationIcon')"/>
              <h3>{{$t('organLocation')}}</h3>
            </span>
            <div class="location-map__content">
              <div class="location-map">
                <span class="map-pin" style="top:18.217%;left:43.4%;">
                  <img src="@/assets/img/main2021/img-location.png" :alt="'정원소재실용화센터(\'26년 예정\)'"/>
                  <p class="map-pin__txt disabled">정원소재실용화센터('26년 예정)</p>
                </span>
                <span class="map-pin" style="top:22.48%;left:54.5%;">
                  <img src="@/assets/img/main2021/img-location.png" :alt="$t('nationalNbgk')"/>
                  <a href="javascript:void(0);" class="map-pin__txt right" ref="nbgk" @click="openGardenModal('CMN000.nbgk');" :title="$t('nationalNbgk')">{{$t('nationalNbgk')}}</a>
                </span>
                <span class="map-pin" style="top:41.47%;left:65.227%">
                  <img src="@/assets/img/main2021/img-location.png" :alt="$t('nationalBdna')"/>
                  <a href="javascript:void(0);" class="map-pin__txt" ref="bdna" @click="openGardenModal('CMN000.bdna');" :title="$t('organLocationImg')">{{$t('nationalBdna')}}</a>
                </span>
                <span class="map-pin" style="top:50.775%;left:33.863%;">
                  <a href="javascript:void(0);" class="map-pin__txt" ref="sjna" @keydown="doLocationMapFocus($event, 'CMN000.sjna')" @click="openGardenModal('CMN000.sjna');">{{$t('nationalSj')}}</a>
                  <img src="@/assets/img/main2021/img-location.png" :alt="$t('koreaKoagi')">
                  <a href="javascript:void(0);" class="map-pin__txt left" ref="koagi" @keydown="doLocationMapFocus($event, 'CMN000.koagi')" @click="openGardenModal('CMN000.koagi');">{{$t('koreaKoagi')}}</a>
                </span>
                <span class="map-pin" style="top: 62%;left:26%;">
                  <img src="@/assets/img/main2021/img-location.png" :alt="'국립새만금수목원(\'27년 예정\)'"/>
                  <p class="map-pin__txt left disabled">국립새만금수목원('27년 예정)</p>
                </span>
                <span class="map-pin" style="top:75.22%;left:32.95%;">
                  <img src="@/assets/img/main2021/img-location.png" :alt="'국립정원문화원'"/>
                  <a href="javascript:void(0);" class="map-pin__txt left" ref="kgcc" @click="openGardenModal('CMN000.kgcc');" :title="국립정원문화원">국립정원문화원</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-top__swiper swiper swiper-container">
        <swiper ref="mainSwiper" class="swiper-wrapper" :options="swiperOption">
          <swiper-slide v-for="(item, index) in mainSlideList" class="swiper-slide" :key="`bg0${index+1}`">
            <a :href="item.url" :target="item.newWinYn=='Y'?'_blank':'_self'" :title="item.url != null && item.url != ''?(item.newWinYn=='Y'?'새창열림':'화면이동'):''" @focus="slideFocus(swiper, index, $event)" @blur="slideBlur(swiper)">
              <div class="swiper-slide" :style="doSwiperStyle(item)">
                <div v-html="$sanitize(item.content)"></div>
              </div>
            </a>
          </swiper-slide>
        </swiper>
      </div>

      <!-- [S] 팝업 모달 -->
      <div :class="orgModalClass">
        <div class="modal-body">
          <button type="button" class="modal-close__btn" id="modalFirst" ref="modalFirst" @click="closeGardenModal()" title="팝업닫기" @keydown="doModalBackKeyDown"></button>
          <div class="garden-modal__content">
            <div class="garden-modal__top">
              <img :src="encodeURI(organ?.imgUrl)" :alt="`${organ.orgNm} ${$t('ciImg')}`"/>
            </div>
            <div class="garden-modal__body">
              <ul class="garden-modal__info">
                <li class="address" v-if="organ.addr != null && organ.addr != ''">
                  <span>{{$t('addr')}}</span>
                  <p>{{organ.addr}}
                    <a v-if="organ.kakaoMapUrl != null && organ.kakaoMapUrl != ''" :href="organ.kakaoMapUrl" target="_blank" class="kakao-map" ></a>
                  </p>
                </li>
                <li class="inq" v-if="organ.tel != null && organ.tel != ''">
                  <span>{{$t('customerqQ')}}</span>
                  <p>
                    <a :href="`tel:${organ.tel}`">{{organ.tel}}</a>
                  </p>
                </li>
                <li class="heart" v-if="organ.openTime != null && organ.openTime != ''">
                  <span>{{$t('openTime')}}</span>
                  <p>{{organ.openTime}}</p>
                </li>
                <li class="star" v-if="organ.holiday != null && organ.holiday != ''">
                  <span>{{$t('orgHoliday')}}</span>
                  <p>{{organ.holiday}}</p>
                </li>
              </ul>
              <div class="garden-modal__bottom">
                <a id="homepage_move" v-if="organ.homepageUrl != null && organ.homepageUrl != ''" :href="organ.homepageUrl" target="_blank" class="garden-direct__link" @keydown="doModalKeyDown">{{$t('homepage')}}</a>
                <div class="modal-sns__list">
                  <a v-if="organ.youTubeUrl != null && organ.youTubeUrl != ''" :href="organ.youTubeUrl" target="_blank" >
                    <img src="@/assets/img/main2021/ico-yt.png" :alt="$t('youtube')"/>
                  </a>
                  <a v-if="organ.instaUrl != null && organ.instaUrl != ''" :href="organ.instaUrl" target="_blank" >
                    <img src="@/assets/img/main2021/ico-insta.png" :alt="$t('instagram')"/>
                  </a>
                  <a v-if="organ.facebookUrl != null && organ.facebookUrl != ''" :href="organ.facebookUrl" target="_blank" >
                    <img src="@/assets/img/main2021/ico-fb.png" :alt="$t('facebook')"/>
                  </a>
                  <a v-if="organ.naverBlogUrl != null && organ.naverBlogUrl != ''" :href="organ.naverBlogUrl" target="_blank" >
                    <img src="@/assets/img/main2021/ico-blog.png" :alt="$t('naverBlog')"/>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- [E] 팝업 모달 -->
    </div>
    <!-- // 메인비주얼 -->

    <!--한수정소식, 메인상단 팝업존, 알림마당-->
    <div class="minfo_wrap">
      <div class="inner">
        <div class="box admin">
          <p class="tit">
            <span><h3>{{$t('koagiNews')}}</h3></span>
            <a href="javascript:void(0);" @click="doContentLink('M0018', 'M0007')" >
              <img src="@/assets/img/main2021/admin_more.gif" :alt="$t('koagiNewsMore')">
            </a>
          </p>
          <a href="javascript:void(0);" v-for="(item, index) in photoList" @click="doBoardDetailLink('M0018', 'M0007', item)" class="contw" :key="`a_${index}`" >
            <p class="adminImg">
              <img :src="encodeURI(item?.imgUrl)" :alt="item.title">
            </p>
            <ul class="adminCont">
              <li>{{item.title}}</li>
              <li class="date">{{item.crtDttm}}</li>
            </ul>
          </a>
        </div>
        <!--메인상단 배너존-->
        <div class="insidePpz">
          <!-- Swiper -->
          <swiper ref="swiper2" class="outsidePpz-container swiper-container" :options="swiperOption2">
            <swiper-slide v-for="(item, index) in slide1List" :key="index">
              <a :href="item.url" :target="item.newWinYn=='Y'?'_blank':'_self'" :title="item.url != null && item.url != ''?(item.newWinYn=='Y'?'새창열림':'화면이동'):''" @focus="slideFocus(swiper2, index, $event)" @blur="slideBlur(swiper2)">
                <div class="mainTopBanner" :style="doSwiperStyle(item)" :title="item.title">
                  <div v-html="$sanitize(item.content)"></div>
                </div>  
              </a>
            </swiper-slide>
          </swiper>
          <ul class="inppz_control">
            <li class="inppz_prev" aria-label="Previous slide">
              <a href="javascript:void(0);" @click="doClickPausePrev2($event)" :title="$t('prevView')">
                <img src="@/assets/img/main2021/prev_ppz.gif" :alt="$t('prevView')">
              </a>
            </li>
            <li class="inppz_stop" :style="inppzStop">
              <!-- <a href="javascript:void(0);" @click="stopAutoplay2" :title="$t('stop')"> -->
                <!-- <img src="@/assets/img/main2021/stop_ppz.gif" :alt="$t('stop')"> -->
              <!-- </a> -->
            </li>
            <!-- <li class="inppz_play" :style="inppzPlay">
              <a href="javascript:void(0);" @click="startAutoplay2" :title="$t('working')">
                <img src="@/assets/img/main2021/play_ppz.gif" :alt="$t('working')">
              </a>
            </li> -->
            <li class="inppz_next" aria-label="Next slide" :title="$t('nextView')">
              <a href="javascript:void(0);"  @click="doClickPauseNext2($event)">
                <img src="@/assets/img/main2021/next_ppz.gif" :alt="$t('nextView')">
              </a>
            </li>
          </ul>
        </div>
        <!--//메인상단 배너존-->

        <!--알림마당-->
        <div class="box notice">
          <p class="tit">
            <span><h3>{{$t('notiField')}}</h3></span>
            <a href="javascript:void(0);" @click="doContentLink('M0024', 'M0007')" >
              <img src="@/assets/img/main2021/notice_more.png" alt="알림마당 더보기">
            </a>
          </p>
          <ul>
            <li v-for="(item, index) in noticeList" :key="`li_${index}`" >
              <a href="javascript:void(0);" @click="doBoardMainLink(item)" :title="`화면이동-${item.title}`">
                <div class="notiDate">
                  <p>{{item.crtDttm.substring(8,10)}}</p>
                  <p>{{item.crtDttm.substring(0,7)}}</p>
                </div>
                <dl class="notiCont">
                  <dt>{{item.title}}</dt>
                  <dd>{{$sanitize(item.ctnt,{allowedTags:['aa']})}}</dd>
                </dl>
              </a>
            </li>
          </ul>
        </div>
        <!--//알림마당-->
      </div>
    </div>
    <!--//관리원활동, 팝업존, 알림마당-->

    <!-- 한국수목원정원관리원 한눈에 보기-->
    <div class="mservice_wrap">
      <div class="wsize">
        <dl class="titw">
          <dt>
            <span><h3>{{$t('mainBizDiv')}}</h3></span>
          </dt>
          <dd>{{$t('message.koagiBizGuid1')}}<br>{{$t('message.koagiBizGuid2')}}</dd>
        </dl>
        <div class="mservice">
          <ul>
            <li class="icon4">
              <a href="javascript:void(0);" @click="doContentLink('M0015', 'M0014')" >
                <span>{{$t('gardenServBiz')}}</span>
              </a>
            </li>
            <li class="icon5">
              <a href="javascript:void(0);" @click="doContentLink('M0016', 'M0014')" >
                <span>{{$t('forestPreservBiz')}}</span>
              </a>
            </li>
            <li class="icon6">
              <a href="javascript:void(0);" @click="doContentLink('M0017', 'M0014')" >
                <span>{{$t('forestUseBiz')}}</span>
              </a>
            </li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mservice_wrap">
      <div class="wsize">
        <dl class="titw">
          <dt>
            <span><h3>{{$t('mainService')}}</h3></span>
          </dt>
          <dd>{{$t('message.koagiBizGuid1')}}<br>{{$t('message.koagiBizGuid2')}}</dd>
        </dl>
        <div class="mservice">
          <ul>
            <li class="icon9">
              <a href="https://garden.koagi.or.kr/cpage.do" target="_blank" >
                <span>{{$t('gardenNuri')}}</span>
              </a>
            </li>
            <li class="icon10">
              <a href="https://reserve.koagi.or.kr/main.do" target="_blank" >
                <span>{{$t('reservSystem')}}</span>
              </a>
            </li>
            <li class="icon12">
              <a href="javascript:void(0);" @click="doContentLink('M0079', 'M0025')" >
                <span>ESG 사업</span>
              </a>
            </li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>
    <!--// 한국수목원정원관리원 한눈에 보기-->

    <!--연보, 외부 팝업존-->
    <div class="mnews_wrap">
      <div class="wsize">
        <div class="mreport">
          <div class="report_cont">
            <dl>
              <dt>{{$t('koagi')}}<br> 
                <span>{{$t('annualReport')}}</span>
              </dt>
              <dd>{{$t('message.annualReport1')}}<br>{{$t('message.annualReport2')}}</dd>
            </dl>
            <router-link :to="{ name: 'dynamicDetail', query: {menuId: 'M0019', upMenuId:'M0007', s0:'3026', postNo: '3866'}}" >{{$t('annualReportGo')}}</router-link>
          </div>
          <router-link :to="{ name: 'dynamicDetail', query: {menuId: 'M0019', upMenuId:'M0007', s0:'3026', postNo: '3866'}}" class="report_book" >
            <img src="@/assets/img/ebook_pdf/homepage2023ebook.png" alt="2023 한국수목원정원관리원 연보">
          </router-link>
        </div>
        <div class="outsidePpz">
          <swiper ref="swiper3" class="outsidePpz-container swiper-container" :options="swiperOption3">
            <swiper-slide v-for="(item, index) in slide2List" :key="index" >
              <a :href="item.url" :target="item.newWinYn=='Y'?'_blank':'_self'" :title="item.url != null && item.url != ''?(item.newWinYn=='Y'?'새창열림':'화면이동'):''" @focus="slideFocus(swiper3, index, $event)" @blur="slideBlur(swiper3)">
                <div class="mainBottomBanner" :style="doSwiperStyle(item)" :title="item.title">
                  <div v-html="$sanitize(item.content)"></div>
                </div>
              </a>
            </swiper-slide>
          </swiper>
          <ul class="outppz_control">
            <li class="outppz_prev" aria-label="Previous slide">
              <a href="javascript:void(0);" @click="doClickPausePrev3($event)" :title="$t('prevView')">
                <img src="@/assets/img/main2021/prev_ppz.gif" :alt="$t('prevView')">
              </a>
            </li>
            <li class="outppz_stop" :style="outppzStop">
              <!-- <a href="javascript:void(0);" @click="stopAutoplay3" :title="$t('stop')">
                <img src="@/assets/img/main2021/stop_ppz.gif" :alt="$t('stop')">
              </a> -->
            </li>
            <!-- <li class="outppz_play" :style="outppzPlay"> -->
              <!-- <a href="javascript:void(0);" @click="startAutoplay3" :title="$t('working')">
                <img src="@/assets/img/main2021/play_ppz.gif" :alt="$t('working')">
              </a> -->
            <!-- </li> -->
            <li class="outppz_next" aria-label="Next slide" :title="$t('nextView')">
              <a href="javascript:void(0);" @click="doClickPauseNext3($event)">
                <img src="@/assets/img/main2021/next_ppz.gif" :alt="$t('nextView')">
              </a>
            </li>
          </ul>
          <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
        </div>
      </div>
    </div>
    <!-- // 연보, 외부 팝업존 -->

    <!-- 관련기관 배너 -->
    <div id="banner">
      <div ref="RollingBanner" id="RollingBanner">
        <ul class="rolling" v-for="(ul, index) in new Array(orgListPageCnt)" :key="`ul_${index}`">
          <template v-if="Rolling.nowno == index">
            <template v-for="(li, index2) in new Array(rollingCnt)">
              <li :key="index2" v-if="orgList.length > ((index)*rollingCnt+index2)">
                <a :href="orgList[(index)*rollingCnt+index2].url" :target="orgList[(index)*rollingCnt+index2].newWinYn=='Y'?'_blank':'_self'" :title="`${orgList[(index)*rollingCnt+index2].title}_${orgList[(index)*rollingCnt+index2].newWinYn=='Y'?'새창열림':'화면이동'}`" @focus="rollingFocus(RollingBanner, index2)" @blur="rollingBlur">
                  <div :style="doSwiperStyle(orgList[(index)*rollingCnt+index2])" class="orgBanner">
                    <div v-html="$sanitize(orgList[(index)*rollingCnt+index2].content)" style="display: grid;"></div>
                  </div>
                </a>
              </li>
            </template>
          </template>
        </ul>
      </div>
      <div class="control">
        <p class="up">
          <a href="javascript:void(0);" @click="Rolling.prev(); return false;" :title="$t('before')">
            <img src="@/assets/img/main/up_btn.png" :alt="$t('before')"/>
          </a>
        </p>
        <p class="down">
          <a href="javascript:void(0);" @click="Rolling.next(); return false;" :title="$t('next')">
            <img src="@/assets/img/main/down_btn.png" :alt="$t('next')"/>
          </a>
        </p>
      </div>
    </div>
    <!-- //관련기관 배너 -->
	</div>
</template>

<script>
import 'swiper/css/swiper.css'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import rotatecontents from "@/libs/rollingBanner/bannerzone"

import { mapGetters } from 'vuex'

export default {
  props: {
    tabindex: {
      type: Number,
      default: 1
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    swiper() {
      return this.$refs.mainSwiper.$swiper;
    },
    swiper2() {
      return this.$refs.swiper2.$swiper;
    },
    swiper3() {
      return this.$refs.swiper3.$swiper;
    },
    RollingBanner() {
      return this.$refs.RollingBanner
    },
    ...mapGetters([
      'popupInfo',
    ]),
  },
  data() {
    return {
      searchParam: {
        orgCd: null,
      },
      swiperOption: {
        loop : true,
        speed : 500,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.mvisual-main-top-slider__control',
          clickable: true,
        },
      },
      swiperOption2: {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        direction:'horizontal',
        loop: false,
        // autoplay: {delay: 4500, disableOnInteraction: false,},
        speed:1000,
      },
      swiperOption3: {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        direction:'horizontal',
        loop: false,
        // autoplay: {delay: 4500, disableOnInteraction: false,},
        speed:1000,
      },
      mainPauseClass: 'pause',
      inppzStop: {display: 'inline-block'},
      inppzPlay: {display: 'none'},
      outppzStop: {display: 'inline-block'},
      outppzPlay: {display: 'none'},
      slide1List: [],
      mainSlideList: [],
      topList: [],
      orgList: [],
      orgListPageCnt: 0,
      Rolling: null,
      rollingCnt: 5,
      slide2List: [],
      noticeList: [],  
      photoList: [],
      organ: {
        orgCd: null,
        orgNm: null,
        addr: null,
        tel: null,
        openTime: null,
        holiday: null,
        homepageUrl: null,
        kakaoMapUrl: null,
        youTubeUrl: null,
        instaUrl: null,
        facebookUrl: null,
        naverBlogUrl: null,
        imgUrl: null,
      },
      locationMapClass: 'location-map__wrap',
      orgModalClass: 'modal-wrap mw',
    }
  },
  created(){
    this.doMain()
  },
  methods: {
    togglePrev() {
      this.swiper.slidePrev();
    },
    toggleNext() {
      this.swiper.slideNext();
    },
    togglePausePlay() {
      if(this.mainPauseClass.includes('run')) {
        this.mainPauseClass = 'pause';
        // this.swiper.autoplay.start();
      }else{
        this.mainPauseClass = 'pause run';
        // this.swiper.autoplay.stop();
      }  
    },
    toggleLocationMap() {
      if(this.locationMapClass.includes('active')) {
        this.locationMapClass = 'location-map__wrap'
      }else{
        this.locationMapClass = 'location-map__wrap active'
      }
    },
    openGardenModal(code) {
      if(code != null && code != '') {
        this.searchParam.orgCd = code;
        this.$tran('/site/external/main/selectOrgan', this.searchParam).then(response => {
          this.organ = response.data.organ;
          this.orgModalClass = `modal-wrap mw${this.organ.orgCd} active`
          this.$store.dispatch('setPopupInfo', 'Y').then(() => {
            this.$refs.modalFirst.focus()
          });
        })
      }
    },
    doLocationMapFocus(event, code) {
      if(event.keyCode == 13 && code != null && code != '') {
        this.openGardenModal(code);
      }
    },
    closeGardenModal() {
      this.orgModalClass = `modal-wrap mw${this.organ.orgCd}`
      this.$store.dispatch('setPopupInfo', 'N');
      const orgCd = this.searchParam.orgCd
      const org = orgCd.substr(orgCd.indexOf(".")+1);

      this.$refs[org].focus()
    },
    doMain() {
      this.$tran('/site/external/main/selectMain', {}).then(response => {
        this.slide1List = response.data.slide1List;
        this.mainSlideList = response.data.mainSlideList;
        this.topList = response.data.topList;
        this.orgList = response.data.orgList;
        this.slide2List = response.data.slide2List;
        this.noticeList = response.data.noticeList;
        this.photoList = response.data.photoList;
        this.organList = response.data.organList;
        this.setMetaInfo();

        this.$nextTick(() => {
          this.orgListPageCnt = Math.ceil((this.orgList.length)/5);
          this.Rolling = new rotatecontents(this.RollingBanner);
          this.Rolling.rotatemethod='vertical';
          this.$nextTick(() => {
            this.Rolling.initialize();
          })
        })
      })
    },
    doContentLink(menuId, upMenuId) {
      this.$router.push({name:'content', query: {menuId: menuId, upMenuId: upMenuId, s0: null}})
    },
    doBoardMainLink(item) {
      const param = {name:'dynamicDetail', query: {menuId: item.menuId, upMenuId: item.upMenuId, postNo: item.postNo}}
      for(const key in item.queryDepth) {
        param.query[key] = item.queryDepth[key]
      }
      this.$router.push(param)
    },
    doBoardDetailLink(menuId, upMenuId, item) {
      this.$router.push({name:'dynamicDetail', query: {menuId: menuId, upMenuId: upMenuId, postNo: item.postNo, s0: item.grpCd}})
    },
    doSwiperStyle(item) {
      return {'background-image': `url("${encodeURI(item?.imgUrl)}")`, 'background-size':'cover'}
    },
    stopAutoplay2() {
      // this.swiper2.autoplay.stop()
      this.inppzPlay.display='inline-block';
      this.inppzStop.display='none'
    },
    startAutoplay2() {
      // this.swiper2.autoplay.start()
      this.inppzStop.display='inline-block';
      this.inppzPlay.display='none'
    },
    stopAutoplay3() {
      // this.swiper3.autoplay.stop()
      this.outppzPlay.display='inline-block';
      this.outppzStop.display='none'
    },
    startAutoplay3() {
      // this.swiper3.autoplay.start()
      this.outppzStop.display='inline-block';
      this.outppzPlay.display='none'
    },
    // 메타태그
    setMetaInfo() {
      const url = document.location.href;
      const img = '/logo.png';

      const metaInfoData = {
        title: `${this.$t('koreaKoagi')}`,
        ogTitle: `${this.$t('koreaKoagi')}`,
        ogUrl: url,
        ogDescription: `${this.$t('koreaKoagi')}`,
        ogImage: img,
      };

      this.$store.dispatch('setMetaInfo', metaInfoData); 
    },
    doClickPausePrev2(event) {
      this.swiper2.slidePrev();
    },
    doClickPauseNext2(event) {
      this.swiper2.slideNext()
    },
    doClickPausePrev3(event) {
      this.swiper3.slidePrev()
    },
    doClickPauseNext3(event) {
      this.swiper3.slideNext()
    },
    doModalBackKeyDown(e) {
      if(e.shiftKey && e.keyCode == 9){
        e.preventDefault();
        document.querySelector("#homepage_move").focus()
      }
    },
    doModalKeyDown(e) {
      if(!e.shiftKey && e.keyCode == 9){
        e.preventDefault();
        document.querySelector("#modalFirst").focus()
      }
    },
    slideFocus(s, i, e){
      // s.autoplay.stop()
      // const aIndex = s.activeIndex;
      // const index = e.target.parentElement.getAttribute('data-swiper-slide-index')
      // console.log(e.target.parentElement)
      // const dIndex = document.activeElement.parentElement.getAttribute('data-swiper-slide-index')
      // if(aIndex != index){
        // if(dIndex != index){
          // console.log("dIndex", dIndex)
          // s.slideTo(dIndex-1,0)
        // }else{
          // s.slideTo(aIndex-1,0)
        // }
      // }
    },
    slideBlur(s){
      // s.autoplay.start()
    },
    rollingFocus(b,i) {
      this.Rolling.stop()
    },
    rollingBlur(b,i) {
      this.Rolling.play()
    },
  },
}
</script>
