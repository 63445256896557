
<template>
  <div class="c-wrapper">
    <LayoutLinkTop @onOverlay="onOverlay" ref="topLink"  :topLinkList="topLinkList"/>
    <layoutTop :snsLinkList="snsLinkList"/>
    <main id="container" class="c-container">
      <!-- Main Container -->
      <section v-if="$route.path !='/eng'" class="c-section pb0">
        <div class="layout" v-if="$route.name != 'eng_dynamicDetail'">
          <h2 class="page-title" id="cont_title">{{subMenuTitle}}</h2>
        </div>
        <router-view />
      </section>
      <template v-if="$route.path =='/eng'">
        <section class="c-section main-top__section">
          <div class="layout">
            <div class="main-top__txts">
              <a href="javascript:void(0);"  @click="doContentLink('M0004', 'M0001')" ><p class="main-top__desc">
                <span class="txt_green">K</span>eep <span class="txt_green">O</span>ur <span class="txt_green">A</span>rboreta and <span class="txt_green">G</span>ardens <span class="txt_green">I</span>nspring
              </p></a>
              <!--
              <h2 class="main-top__title">
                <svg width="827" height="28" viewBox="0 0 827 28" fill="none" xmlns="http://www.w3.org/2000/svg" aria-labelledby="mainTopTitle">
                  <g clip-path="url(#clip0_806_39)">
                    <path d="M0 27.0666V24.4894H2.31563V3.87176H0V1.29456H8.83722V3.87176H6.93509L6.97053 13.6532L15.1816 3.87176H13.2204V1.29456H19.8719V3.87176H18.3242L11.082 12.5144L19.9546 24.4894H21.715V27.0666H12.9014V24.4894H14.4609L6.92327 14.3724V24.4894H8.8254V27.0666H0Z" fill="#8CBA61"/>
                    <path d="M37.4753 27.0667C33.8837 27.0667 31.0483 25.856 28.9807 23.4466C26.9132 21.0252 25.8735 17.7288 25.8735 13.5333C25.8735 9.33788 26.9132 6.04145 28.9807 3.62008C31.0483 1.21069 33.8837 0 37.4753 0C41.0669 0 43.9024 1.21069 45.9817 3.62008C48.0611 6.04145 49.1008 9.33788 49.1008 13.5333C49.1008 17.7288 48.0611 21.0252 45.9817 23.4466C43.9024 25.856 41.0669 27.0667 37.4753 27.0667ZM37.4753 24.4895C39.4365 24.4895 41.0079 23.4945 42.2011 21.4807C43.3826 19.4789 43.9733 16.8298 43.9733 13.5333C43.9733 10.2369 43.3826 7.57579 42.2011 5.57396C41.0197 3.57213 39.4483 2.57721 37.4753 2.57721C35.5023 2.57721 33.9546 3.57213 32.7732 5.57396C31.5917 7.57579 31.001 10.2249 31.001 13.5333C31.001 16.8417 31.5917 19.5029 32.7732 21.4927C33.9546 23.4945 35.5259 24.4895 37.4753 24.4895Z" fill="#8CBA61"/>
                    <path d="M60.7261 16.0626V24.4894H62.6637V27.0666H53.791V24.4894H56.1067V3.87176H53.791V1.29456H63.7506C66.2435 1.29456 68.1574 1.85795 69.5043 2.97274C70.8511 4.08753 71.5245 5.6818 71.5245 7.75555C71.5245 9.50566 71.1465 10.992 70.4021 12.2147C69.6578 13.4374 68.5355 14.3844 67.0232 15.0556L73.1195 24.5014H74.6672V27.0786H68.7363L62.4983 16.0745H60.7143L60.7261 16.0626ZM60.7261 13.5932H61.5413C63.3017 13.5932 64.6131 13.1377 65.4991 12.2147C66.3734 11.2917 66.8106 9.90123 66.8106 8.05523C66.8106 6.66474 66.4325 5.62187 65.6764 4.92662C64.9202 4.23137 63.786 3.88375 62.2738 3.88375H60.7261V13.6052V13.5932Z" fill="#8CBA61"/>
                    <path d="M96.1814 27.0666H78.6724V24.4894H80.988V3.87176H78.6724V1.29456H95.4489V6.44897H92.9442V3.87176H85.8083V12.4545H90.6404V10.7283H92.9561V16.7578H90.6404V15.0317H85.8083V24.2737H93.6767V21.4687H96.1814V27.0546V27.0666Z" fill="#8CBA61"/>
                    <path d="M105.621 19.5988L103.885 24.4895H106.2V27.0667H99.9385V24.4895H101.309L109.65 0.958984H113.265L121.594 24.4895H122.953V27.0667H114.317V24.4895H116.632L114.884 19.5988H105.609H105.621ZM106.566 16.9137H113.927L110.241 6.55692L106.566 16.9137Z" fill="#8CBA61"/>
                  </g>
                  <g clip-path="url(#clip0_806_39)">
                    <path d="M144.42 19.5988L142.683 24.4895H144.999V27.0667H138.737V24.4895H140.108L148.449 0.958984H152.064L160.393 24.4895H161.752V27.0667H153.116V24.4895H155.431L153.694 19.5988H144.42ZM145.365 16.9137H152.726L149.04 6.55692L145.365 16.9137Z" fill="#8CBA61"/>
                    <path d="M172.397 16.0626V24.4894H174.334V27.0666H165.461V24.4894H167.777V3.87176H165.461V1.29456H175.421C177.914 1.29456 179.828 1.85795 181.175 2.97274C182.522 4.08753 183.195 5.6818 183.195 7.75555C183.195 9.50566 182.817 10.992 182.073 12.2147C181.328 13.4374 180.206 14.3844 178.694 15.0556L184.79 24.5014H186.338V27.0786H180.407L174.169 16.0745H172.385L172.397 16.0626ZM172.397 13.5932H173.212C174.972 13.5932 176.283 13.1377 177.17 12.2147C178.044 11.2917 178.481 9.90123 178.481 8.05523C178.481 6.66474 178.103 5.62187 177.347 4.92662C176.591 4.23137 175.456 3.88375 173.944 3.88375H172.397V13.6052V13.5932Z" fill="#8CBA61"/>
                    <path d="M201.838 13.6891C203.977 14.1566 205.583 14.9717 206.647 16.1225C207.71 17.2852 208.253 18.7836 208.253 20.6296C208.253 22.7633 207.616 24.3695 206.34 25.4484C205.064 26.5272 203.15 27.0546 200.586 27.0546H190.343V24.4774H192.658V3.87176H190.343V1.29456H200.61C202.748 1.29456 204.39 1.79801 205.56 2.79293C206.729 3.78785 207.308 5.20232 207.308 7.02435C207.308 8.52272 206.859 9.82931 205.95 10.9321C205.04 12.0469 203.669 12.9579 201.85 13.6891H201.838ZM197.006 12.5623H197.951C199.523 12.5623 200.704 12.1668 201.484 11.3756C202.264 10.5845 202.653 9.39777 202.653 7.82748C202.653 6.38903 202.299 5.37014 201.59 4.77079C200.881 4.17144 199.688 3.87176 197.999 3.87176H197.006V12.5623ZM197.006 24.4894H198.046C199.983 24.4894 201.366 24.1538 202.216 23.4705C203.067 22.7873 203.492 21.6845 203.492 20.1501C203.492 18.6158 202.972 17.4291 201.945 16.4701C200.917 15.5111 199.57 15.0317 197.928 15.0317H197.006V24.4894Z" fill="#8CBA61"/>
                    <path d="M224.829 27.0667C221.238 27.0667 218.402 25.856 216.335 23.4466C214.267 21.0252 213.228 17.7288 213.228 13.5333C213.228 9.33788 214.267 6.04145 216.335 3.62008C218.402 1.21069 221.238 0 224.829 0C228.421 0 231.256 1.21069 233.336 3.62008C235.415 6.04145 236.455 9.33788 236.455 13.5333C236.455 17.7288 235.415 21.0252 233.336 23.4466C231.256 25.856 228.421 27.0667 224.829 27.0667ZM224.829 24.4895C226.791 24.4895 228.362 23.4945 229.555 21.4807C230.737 19.4789 231.327 16.8298 231.327 13.5333C231.327 10.2369 230.737 7.57579 229.555 5.57396C228.374 3.57213 226.802 2.57721 224.829 2.57721C222.856 2.57721 221.309 3.57213 220.127 5.57396C218.946 7.57579 218.355 10.2249 218.355 13.5333C218.355 16.8417 218.946 19.5029 220.127 21.4927C221.309 23.4945 222.88 24.4895 224.829 24.4895Z" fill="#8CBA61"/>
                    <path d="M248.08 16.0626V24.4894H250.018V27.0666H241.145V24.4894H243.461V3.87176H241.145V1.29456H251.105C253.597 1.29456 255.511 1.85795 256.858 2.97274C258.205 4.08753 258.879 5.6818 258.879 7.75555C258.879 9.50566 258.5 10.992 257.756 12.2147C257.012 13.4374 255.889 14.3844 254.389 15.0556L260.485 24.5014H262.033V27.0786H256.102L249.864 16.0745H248.08V16.0626ZM248.08 13.5932H248.895C250.656 13.5932 251.967 13.1377 252.853 12.2147C253.727 11.2917 254.165 9.90123 254.165 8.05523C254.165 6.66474 253.786 5.62187 253.03 4.92662C252.274 4.23137 251.14 3.88375 249.628 3.88375H248.08V13.6052V13.5932Z" fill="#8CBA61"/>
                    <path d="M283.535 27.0666H266.026V24.4894H268.342V3.87176H266.026V1.29456H282.803V6.44897H280.298V3.87176H273.162V12.4545H277.994V10.7283H280.31V16.7578H277.994V15.0317H273.162V24.2737H281.031V21.4687H283.535V27.0546V27.0666Z" fill="#8CBA61"/>
                    <path d="M295.799 4.07554H289.927V6.85653H287.422V1.29456H308.996V6.86852H306.491V4.08753H300.619V24.5014H302.935V27.0786H293.483V24.5014H295.799V4.07554Z" fill="#8CBA61"/>
                    <path d="M318.317 19.5988L316.58 24.4895H318.896V27.0667H312.634V24.4895H314.005L322.346 0.958984H325.961L334.29 24.4895H335.649V27.0667H327.012V24.4895H329.328L327.58 19.5988H318.305H318.317ZM319.262 16.9137H326.623L322.936 6.55692L319.262 16.9137Z" fill="#8CBA61"/>
                  </g>
                  <g clip-path="url(#clip0_806_39)">
                    <path d="M356.513 19.5988L354.777 24.4895H357.092V27.0667H350.831V24.4895H352.201L360.542 0.958984H364.157L372.486 24.4895H373.845V27.0667H365.209V24.4895H367.524L365.776 19.5988H356.501H356.513ZM357.458 16.9137H364.819L361.133 6.55692L357.458 16.9137Z" fill="#8CBA61"/>
                    <path d="M394.52 27.0667L383.037 8.77449V24.2257H385.352V26.803H377.555V24.2257H379.87V3.60809H377.555V1.03088H383.167L394.662 19.4429V3.60809H392.346V1.03088H400.144V3.60809H397.828V27.0667H394.52Z" fill="#8CBA61"/>
                    <path d="M404.574 27.0666V24.4894H406.89V3.87176H404.574V1.29456H415.987C419.65 1.29456 422.509 2.38537 424.564 4.579C426.62 6.77262 427.648 9.81732 427.648 13.7131C427.648 17.6089 426.656 21.0012 424.659 23.4346C422.662 25.8559 420.004 27.0786 416.672 27.0786H404.562L404.574 27.0666ZM411.71 24.2856H414.369C417.086 24.2856 419.13 23.3986 420.488 21.6245C421.847 19.8504 422.532 17.2013 422.532 13.6771C422.532 10.4047 421.823 7.94735 420.418 6.31711C419 4.68688 416.885 3.87176 414.061 3.87176H411.71V24.2856Z" fill="#8CBA61"/>
                  </g>
                  <g clip-path="url(#clip0_806_39)">
                    <path d="M464.899 25.88C461.721 26.6711 459.11 27.0667 457.054 27.0667C453.415 27.0667 450.521 25.844 448.382 23.4106C446.244 20.9773 445.181 17.6689 445.181 13.4974C445.181 9.32589 446.232 5.89761 448.347 3.53617C450.45 1.17473 453.427 0 457.279 0C458.637 0 459.972 0.0839091 461.272 0.275701C462.572 0.455506 463.848 0.731208 465.1 1.1028V6.56888H462.477L462.288 3.99167C461.437 3.51219 460.599 3.16457 459.772 2.92483C458.945 2.69708 458.129 2.57721 457.314 2.57721C455.128 2.57721 453.415 3.56014 452.175 5.51402C450.934 7.46791 450.308 10.165 450.308 13.6053C450.308 17.0455 451.005 19.6227 452.387 21.5526C453.77 23.4825 455.672 24.4535 458.082 24.4535C458.744 24.4535 459.417 24.3216 460.079 24.0579V17.3572H457.763V14.78H466.636V17.3572H464.899V25.8919V25.88Z" fill="#8CBA61"/>
                    <path d="M476.56 19.5988L474.823 24.4895H477.139V27.0667H470.877V24.4895H472.247L480.588 0.958984H484.204L492.533 24.4895H493.892V27.0667H485.255V24.4895H487.571L485.834 19.5988H476.572H476.56ZM477.505 16.9137H484.865L481.179 6.55692L477.505 16.9137Z" fill="#8CBA61"/>
                    <path d="M504.537 16.0626V24.4894H506.474V27.0666H497.602V24.4894H499.917V3.87176H497.602V1.29456H507.561C510.054 1.29456 511.968 1.85795 513.315 2.97274C514.662 4.08753 515.335 5.6818 515.335 7.75555C515.335 9.50566 514.957 10.992 514.213 12.2147C513.468 13.4374 512.346 14.3844 510.834 15.0556L516.93 24.5014H518.478V27.0786H512.547L506.321 16.0745H504.537V16.0626ZM504.537 13.5932H505.352C507.112 13.5932 508.424 13.1377 509.298 12.2147C510.172 11.2917 510.609 9.90123 510.609 8.05523C510.609 6.66474 510.231 5.62187 509.475 4.92662C508.719 4.23137 507.585 3.88375 506.073 3.88375H504.525V13.6052L504.537 13.5932Z" fill="#8CBA61"/>
                    <path d="M522.482 27.0666V24.4894H524.798V3.87176H522.482V1.29456H533.895C537.558 1.29456 540.417 2.38537 542.473 4.579C544.528 6.77262 545.556 9.81732 545.556 13.7131C545.556 17.6089 544.564 21.0012 542.567 23.4346C540.57 25.8559 537.912 27.0786 534.581 27.0786H522.471L522.482 27.0666ZM529.618 24.2856H532.277C534.994 24.2856 537.038 23.3986 538.397 21.6245C539.755 19.8504 540.44 17.2013 540.44 13.6771C540.44 10.4047 539.732 7.94735 538.326 6.31711C536.908 4.68688 534.793 3.87176 531.97 3.87176H529.618V24.2856Z" fill="#8CBA61"/>
                    <path d="M567.78 27.0666H550.271V24.4894H552.586V3.87176H550.271V1.29456H567.047V6.44897H564.542V3.87176H557.406V12.4545H562.227V10.7283H564.542V16.7578H562.227V15.0317H557.406V24.2737H565.275V21.4687H567.768V27.0546L567.78 27.0666Z" fill="#8CBA61"/>
                    <path d="M588.963 27.0667L577.479 8.77449V24.2257H579.795V26.803H571.997V24.2257H574.313V3.60809H571.997V1.03088H577.609L589.104 19.4429V3.60809H586.789V1.03088H594.586V3.60809H592.271V27.0667H588.963Z" fill="#8CBA61"/>
                    <path d="M599.442 26.0358V20.318H602.148V23.003C602.915 23.5305 603.648 23.926 604.321 24.1898C605.007 24.4535 605.645 24.5853 606.235 24.5853C607.393 24.5853 608.326 24.2377 609.035 23.5305C609.744 22.8232 610.099 21.9122 610.099 20.7735C610.099 19.6946 609.874 18.8196 609.425 18.1483C608.964 17.489 608.102 16.7578 606.814 15.9667L605.267 15.0317C603.175 13.7491 601.687 12.4904 600.801 11.2917C599.915 10.093 599.477 8.72652 599.477 7.20417C599.477 5.09445 600.163 3.36833 601.533 2.02578C602.904 0.683235 604.676 0.0119629 606.85 0.0119629C607.984 0.0119629 609.106 0.0958719 610.229 0.275677C611.351 0.455482 612.462 0.719196 613.56 1.07881V6.30514H610.855V3.83582C610.193 3.38031 609.567 3.04468 608.953 2.81692C608.338 2.58917 607.736 2.48129 607.157 2.48129C606.271 2.48129 605.515 2.82891 604.9 3.52416C604.286 4.2194 603.967 5.07048 603.967 6.0654C603.967 6.88052 604.191 7.57577 604.64 8.15114C605.089 8.73851 605.928 9.43375 607.169 10.2249L608.728 11.2318C610.973 12.6702 612.532 13.9768 613.383 15.1396C614.234 16.3143 614.659 17.7048 614.659 19.323C614.659 21.6845 613.867 23.5664 612.296 24.9689C610.725 26.3834 608.634 27.0906 606.023 27.0906C605.054 27.0906 604.026 27.0067 602.927 26.8269C601.829 26.6591 600.659 26.3954 599.43 26.0597L599.442 26.0358Z" fill="#8CBA61"/>
                  </g>
                  <g clip-path="url(#clip0_806_39)">
                    <path d="M638.855 3.87176V24.4894H641.17V27.0666H631.719V24.4894H634.035V3.87176H631.719V1.29456H641.17V3.87176H638.855Z" fill="#8CBA61"/>
                    <path d="M662.555 27.0667L651.071 8.77449V24.2257H653.387V26.803H645.589V24.2257H647.905V3.60809H645.589V1.03088H651.201L662.697 19.4429V3.60809H660.381V1.03088H668.179V3.60809H665.863V27.0667H662.555Z" fill="#8CBA61"/>
                    <path d="M673.046 26.0358V20.318H675.751V23.003C676.519 23.5305 677.252 23.926 677.925 24.1898C678.611 24.4535 679.249 24.5853 679.839 24.5853C680.997 24.5853 681.93 24.2377 682.639 23.5305C683.348 22.8232 683.703 21.9122 683.703 20.7735C683.703 19.6946 683.478 18.8196 683.029 18.1483C682.568 17.489 681.706 16.7578 680.418 15.9667L678.871 15.0317C676.779 13.7491 675.291 12.4904 674.405 11.2917C673.519 10.093 673.081 8.72652 673.081 7.20417C673.081 5.09445 673.767 3.36833 675.137 2.02578C676.508 0.683235 678.28 0.0119629 680.454 0.0119629C681.588 0.0119629 682.71 0.0958719 683.833 0.275677C684.955 0.455482 686.066 0.719196 687.164 1.07881V6.30514H684.459V3.83582C683.797 3.38031 683.171 3.04468 682.557 2.81692C681.942 2.58917 681.34 2.48129 680.761 2.48129C679.875 2.48129 679.119 2.82891 678.504 3.52416C677.89 4.2194 677.571 5.07048 677.571 6.0654C677.571 6.88052 677.795 7.57577 678.244 8.15114C678.693 8.73851 679.532 9.43375 680.773 10.2249L682.332 11.2318C684.577 12.6702 686.136 13.9768 686.987 15.1396C687.838 16.3143 688.263 17.7048 688.263 19.323C688.263 21.6845 687.471 23.5664 685.9 24.9689C684.329 26.3834 682.238 27.0906 679.627 27.0906C678.658 27.0906 677.63 27.0067 676.531 26.8269C675.433 26.6591 674.263 26.3954 673.034 26.0597L673.046 26.0358Z" fill="#8CBA61"/>
                    <path d="M700.834 4.07554H694.962V6.85653H692.457V1.29456H714.03V6.86852H711.526V4.08753H705.654V24.5014H707.969V27.0786H698.518V24.5014H700.834V4.07554Z" fill="#8CBA61"/>
                    <path d="M725.253 3.87176V24.4894H727.569V27.0666H718.118V24.4894H720.434V3.87176H718.118V1.29456H727.569V3.87176H725.253Z" fill="#8CBA61"/>
                    <path d="M740.034 4.07554H734.162V6.85653H731.658V1.29456H753.231V6.86852H750.726V4.08753H744.855V24.5014H747.17V27.0786H737.719V24.5014H740.034V4.07554Z" fill="#8CBA61"/>
                    <path d="M759.244 3.22455H756.929V0.647339H766.38V3.22455H764.065V16.7219C764.065 19.443 764.466 21.4088 765.258 22.6435C766.049 23.8782 767.325 24.4895 769.086 24.4895C770.846 24.4895 772.004 23.8902 772.713 22.6915C773.422 21.4927 773.776 19.4789 773.776 16.626V3.22455H771.46V0.647339H779.565V3.22455H777.25V16.1585C777.25 18.3761 777.096 20.0783 776.777 21.253C776.458 22.4277 775.879 23.5186 775.04 24.5135C773.575 26.2036 771.413 27.0547 768.554 27.0547C765.388 27.0547 763.037 26.2036 761.513 24.4895C759.989 22.7873 759.221 20.1622 759.221 16.614V3.22455H759.244Z" fill="#8CBA61"/>
                    <path d="M791.651 4.07554H785.78V6.85653H783.275V1.29456H804.848V6.86852H802.343V4.08753H796.472V24.5014H798.787V27.0786H789.336V24.5014H791.651V4.07554Z" fill="#8CBA61"/>
                    <path d="M826.445 27.0666H808.936V24.4894H811.252V3.87176H808.936V1.29456H825.713V6.44897H823.208V3.87176H816.072V12.4545H820.892V10.7283H823.208V16.7578H820.892V15.0317H816.072V24.2737H823.94V21.4687H826.433V27.0546L826.445 27.0666Z" fill="#8CBA61"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_806_39">
                      <rect width="827" height="28" fill="white"/>
                    </clipPath>
                  </defs>
                  <title id="mainTopTitle">KOREA ARBORETA AND GARDENS INSTITUTE</title>
                </svg>                            
              </h2>
              -->
            </div>

            <div class="location-map__wrap" :class="locationMap">
              <button type="button" class="location-map__btn" @click="toggleLocationMap()">
                <p>Affiliated Organizations <span class="btn_do"></span></p>
              </button>
              <div class="location-map__content">
                <div class="location-map" >
                  <a href="javascript:void(0);" class="map-pin" style="top:18.217%;left:43.4%;" title="Garden Materials Utilization Center (2025)">
                    <img src="@/assets/img/eng/img-location.png"/>
                    <p class="map-pin__txt disabled skew-left">
                      Korean Garden Industry <br/>Material Center (Open In 2026)
                    </p>
                  </a>
                  <a href="javascript:void(0);" class="map-pin" style="top:22.48%;left:54.5%;" @click="openGardenModal('CMN000.nbgkE');" @keydown="doLocationMapFocus($event, 'CMN000.nbgk')" titl="The National Botanic Garden of Korean Native Plants">
                    <img src="@/assets/img/eng/img-location.png"/>
                    <p class="map-pin__txt skew-right">
                      National Botanic Garden<br/>of Korean Native Plants
                    </p>
                  </a>
                  <a href="javascript:void(0);" class="map-pin" style="top:41.47%;left:65.227%" @click="openGardenModal('CMN000.bdnaE');" title="Baekdudaegan National Arboretum">
                    <img src="@/assets/img/eng/img-location.png"/>
                    <p class="map-pin__txt">
                      Baekdudaegan National<br/>Arboretum
                    </p>
                  </a>
                  <div class="map-pin" style="top:50.775%;left:33.863%;">
                    <a href="javascript:void(0);" @click="openGardenModal('CMN000.sjnaE');" title="Sejong National Arboretum" class="map-pin__txt">
                      Sejong National<br/>Arboretum
                    </a> 
                    <img src="@/assets/img/eng/img-location.png"/>
                    <a href="javascript:void(0);" class="map-pin__txt left" @click="openGardenModal('CMN000.koagiE');" title="Korea Arboreta and Gardens Institute">
                      Headquarters
                    </a>
                  </div>
                  <a href="javascript:void(0);" class="map-pin" style="top: 62%;left: 26%;" title="The Korea Garden Culture Institute (2024)">
                    <img src="@/assets/img/eng/img-location.png"/>
                    <p class="map-pin__txt left disabled">
                      Saemangeum National Arboretum<br/>(Open In 2027)
                    </p>
                  </a>
                  <a href="javascript:void(0);" class="map-pin" style="top: 75.7%;left: 33.045%;" title="The Korea Garden Culture Institute (2024)">
                    <img src="@/assets/img/eng/img-location.png"/>
                    <p class="map-pin__txt left">
                      Korean Garden Culture Center
                    </p>
                  </a>

                </div>
            </div>
          </div>
          <!-- [S] 팝업 모달 -->
          <div class="modal-wrap" :class="orgModalClass">
            <div class="modal-body">
              <button type="button" class="modal-close__btn" id="modalFirst" ref="modalFirst" title="Close Popup" @click="closeGardenModal();" @keydown="doModalBackKeyDown"></button>
                <div class="garden-modal__content">
                  <div class="garden-modal__top">
                    <img :src="encodeURI(organ?.imgUrl)"/>
                  </div>
                  <div class="garden-modal__body">
                    <ul class="garden-modal__info">
                      <li class="address" v-if="organ.addr != null && organ.addr != ''">
                        <span>Address</span>
                        <p>{{ organ.addr }}
                          <a v-if="organ.kakaoMapUrl != null && organ.kakaoMapUrl != ''" :href="organ.kakaoMapUrl" target="_blank" class="kakao-map" title="google-map"></a>
                        </p>
                      </li>
                      <li class="inq" v-if="organ.tel != null && organ.tel != ''">
                        <span>Telephone </span>
                        <p>
                          <a :href="`tel:${organ.tel}`" :title="organ.tel">{{organ.tel}}</a>
                        </p>
                      </li>
                      <li class="heart" v-if="organ.openTime != null && organ.openTime != ''">
                        <span>Opening Hours</span>
                        <p>{{ organ.openTime }}</p>
                      </li>
                      <li class="star" v-if="organ.holiday != null && organ.holiday != ''">
                        <span>Closing days</span>
                        <p>{{organ.holiday}}</p>
                      </li>
                    </ul>
                    <div class="garden-modal__bottom">
                      <a id="homepage_move" v-if="organ.homepageUrl != null && organ.homepageUrl != ''" :href="organ.homepageUrl" target="_blank" class="garden-direct__link" @keydown="doModalKeyDown" title="Homepage_New window opening">
                        Homepage
                      </a>
                      <div class="modal-sns__list">
                        <a v-if="organ.youTubeUrl != null && organ.youTubeUrl != ''" :href="organ.youTubeUrl" target="_blank" title="YouTube_New window opening">
                          <img src="@/assets/img/eng/ico-yt.png" alt="youtube"/>
                        </a>
                        <a v-if="organ.instaUrl != null && organ.instaUrl != ''" :href="organ.instaUrl" target="_blank" title="instagram_New window opening">
                          <img src="@/assets/img/eng/ico-insta.png" alt="instagram"/>
                        </a>
                        <a v-if="organ.facebookUrl != null && organ.facebookUrl != ''" :href="organ.facebookUrl" target="_blank" title="facebook_New window opening">
                          <img src="@/assets/img/eng/ico-fb.png" alt="facebook"/>
                        </a>
                        <a v-if="organ.naverBlogUrl != null && organ.naverBlogUrl != ''" :href="organ.naverBlogUrl" target="_blank" title="naverBlog_New window opening">
                          <img src="@/assets/img/eng/ico-blog.png" alt="naverBlog"/>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- [E] 팝업 모달 -->
          </div>
        </section>
        <section class="main-slider swiper-container">
          <swiper ref="mainSwiper" :options="swiperOption">
            <swiper-slide v-for="(item, index) in mainSlideList" :key="index">
              <a :href="doSlideLink(item.url)" :target="item.newWinYn=='Y'?'_blank':'_self'" :title="item.url != null && item.url != ''?(item.newWinYn=='Y'?'New window opening':'Move Page'):''">
                <div class="swiper-slide" :style="doSwiperStyle(item)">
                  <div v-html="$sanitize(item.content)"></div>
                </div>
              </a>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>  
        </section>
        <section class="c-section main-mainbiz__section">
          <div class="layout">
            <!-- 20240520 영문홈페이지 메인페이지 수정으로 Main Business, 배너슬라이드 제외
            <div class="main-mainbiz__left">
              <h4>Main Business</h4>
              <p>
                We aim to create an arboretum that brings<br/>
                happiness to the public and provides a<br/>
                peaceful sanctuary for plants.
              </p>
              <div class="mainbiz-controls">
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>
            </div>
            <div class="main-mainbiz__right">
              <swiper ref="swiper2" class="swiper-wrapper main-mainbiz__slider" :options="swiperOption2">
                <swiper-slide v-for="(item, index) in slide1List" class="swiper-slide" :key="index">
                  <a :href="doSlideLink(item.url)" :target="item.newWinYn=='Y'?'_blank':'_self'" :title="item.url != null && item.url != ''?(item.newWinYn=='Y'?'New window opening':'Move Page'):''">
                    <div class="swiper-slide" :style="doSwiperStyle(item)">
                      <div v-html="$sanitize(item.content)"></div>
                    </div>
                  </a>
                </swiper-slide>
              </swiper>
              -->
            <div class="main-mainbiz__right">
              <div v-for="(item, index) in slide1List" :key="index">
                <div>
                  <div v-html="$sanitize(item.content)"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template> 
    </main>
    <layoutFooter :siteList="siteList"/>
    <template v-if="$route.path =='/eng'">
      <template v-for="(item, index) in layerPopupList">
        <div v-if="doCookie(item)" class="layer-popup lplp" :style="item.layerStyle != null? item.layerStyle :doLayerStyle(item)" :key="index">
          <div v-html="item.content" class="layerCont"></div>
          <div class="today-close">
            <ul>
              <input type="checkbox" v-if="item.nextCloseYn == 'Y'" v-model="item.layerNextCloseYn" true-value="Y" false-value="N">
              <label for="next_close" v-if="item.nextCloseYn == 'Y'">{{ item.nextCloseDd }}{{$t('nextCloseDdText')}}</label>
              <input type="button" value="Close" @click="doNextClose(item, item.layerNextCloseYn)" class="ct_bt01">
            </ul>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import 'swiper/css/swiper.css'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import LayoutLinkTop from '@/views_eng/layouts/LayoutLinkTop'
import layoutTop from '@/views_eng/layouts/LayoutTop'
import layoutFooter from '@/views_eng/layouts/LayoutFooter'

export default {
  components: {
    LayoutLinkTop,
    layoutTop,
    layoutFooter,
    Swiper,
    SwiperSlide,
  },
  computed: {
    swiper() {
      return this.$refs.mainSwiper.$swiper;
    },
    swiper2() {
      return this.$refs.swiper2;
    },
    ...mapGetters([
      'subMenuTitle',
    ]),
  },
  created() {
    this.doMainContent();
    this.doMain()
  },
  data() {
    return {
      searchParam: {
        orgCd: null,
      },
      layerPopupList: [],
      windowPopupList: [],
      locationMap: 'active',
      topLinkList:[],
      snsLinkList:[],
      siteList:[],
      mainSlideList: [],
      slide1List: [],
      orgModalClass: '',
      organ: {
        orgCd: null,
        orgNm: null,
        addr: null,
        tel: null,
        openTime: null,
        holiday: null,
        homepageUrl: null,
        kakaoMapUrl: null,
        youTubeUrl: null,
        instaUrl: null,
        facebookUrl: null,
        naverBlogUrl: null,
        imgUrl: null,
      },
      swiperOption: {
        loop: true,
        autoplay: {
            delay: 3000,
        },
        speed: 1000,
        slidesPerView: 1,
        navigation: {
          prevEl: '.main-slider .swiper-button-prev',
          nextEl: '.main-slider .swiper-button-next',
        },      
      },
      swiperOption2: {
        speed: 1000,
        spaceBetween : 20,
        slidesPerView: 1,
        navigation: {
          prevEl: '.mainbiz-controls .swiper-button-prev',
          nextEl: '.mainbiz-controls .swiper-button-next',
        },
      },
      doContentLink(menuId, upMenuId) {
      this.$router.push({name:'eng_content', query: {menuId: menuId, upMenuId: upMenuId, s0: null}})
    },

    }
  },
  methods: {
    toggleLocationMap() {
      if (this.locationMap == 'active') {
        this.locationMap = '';
      } else {
        this.locationMap = 'active';
      }
    },
    doMainContent() {
      this.$tran('/site/external/main/selectMainContent', '').then(response => {
        this.snsLinkList = response.data.snsLinkList;
        this.topLinkList = response.data.topLinkList;
        this.layerPopupList = response.data.layerPopupList;
        this.windowPopupList = response.data.windowPopupList;
        this.siteList = response.data.siteList;

        this.$nextTick(() => {
          if(this.windowPopupList != null && this.windowPopupList.length > 0 && this.$route.path =='/eng') {
            this.doWindowPopup();
          }
        })
      })
    },
    openGardenModal(code) {
      if(code != null && code != '') {
        this.searchParam.orgCd = code;
        this.$tran('/site/external/main/selectOrgan', this.searchParam).then(response => {
          this.organ = response.data.organ;
          this.orgModalClass = 'active';
          this.$store.dispatch('setPopupInfo', 'Y').then(() => {
            this.$refs.modalFirst.focus()
          });
        })
      }
    },
    doMain() {
      this.$tran('/site/external/main/selectMain', {}).then(response => {
        this.slide1List = response.data.slide1List;
        this.mainSlideList = response.data.mainSlideList;
      })
    },
    closeGardenModal() {
      this.orgModalClass = [];
      this.$store.dispatch('setPopupInfo', 'N');
    },
    doSwiperStyle(item) {
      return {'background-image': `url("${encodeURI(item?.imgUrl)}")`, 'background-size':'cover'}
    },
    doCookie(item) {
      const data = this.$cookies.get(item.popupNo);

      if(data != null) {
        return false;
      }
      return true;
    },
    doNextClose(item, layerNextCloseYn) {
      if(layerNextCloseYn == 'Y') {
        this.$cookies.set(item.popupNo, 'Y', `${item.nextCloseDd}d`);
      }
      
      this.doLayerClose(item);
    },
    doLayerClose(item) {
      item.layerStyle.display = 'none';
    },
    doLayerStyle(item) {
      const width = item.widthPixel;
      const height = item.heightPixel;
      const left = item.leftPixel;
      const top = item.topPixel;

      const layerStyle = {
        backgroundImage: `url(${encodeURI(item?.imgUrl)})`,
        width: `${width}px`,
        height: `${Number(height)+30}px`,
        left: `${left}px`,
        top: `${top}px`,
        display: 'block',
        'background-size': 'contain',
        backgroundRepeat: 'no-repeat'
      }

      this.$set(item, 'layerStyle', layerStyle);
    },
    doSlideLink(url) {
      if(url != null && url != '') {
        return url
      }

      return false
    },
    doWindowPopup() {
      for(let i=0; i < this.windowPopupList.length; i++) {
        const getCookie = this.doCookie(this.windowPopupList[i]);

        if(getCookie) {
          const data = this.windowPopupList[i]
          const popupNo = data.popupNo;
          const imgUrl = data.imgUrl;
          const width = data.widthPixel;
          let height = data.heightPixel;
          const left = data.leftPixel;
          const top = data.topPixel;
          const content = data.content;
          const nextCloseDd = data.nextCloseDd;
          const nextCloseYn = data.nextCloseYn;

          
          if(height != null && typeof height == 'string'){
            const intData = height.replaceAll(/(-)[^\d.]/gi, '')
            if(intData != null && intData != ''){
              height = (parseFloat(intData) + 30) + height.replaceAll(/[-\d.]/gi,'')
            }
          }
          
          const win = window.open('', popupNo, `location=no,width=${width},height=${height},top=${top},left=${left}`);

          if(win.document.querySelector('.newPopupDiv') != null) {
            win.document.querySelector('.newPopupDiv').remove();
            win.document.querySelector('.popup').remove();
          }

          if(win.document.querySelector('.footer') != null) {
            win.document.querySelector('.footer').remove();
          }

          if(win.document.querySelector('script') != null) {
            win.document.querySelector('script').remove();
          }

          if(win.document.querySelector('style') != null) {
            win.document.querySelector('style').remove();
          }
          
          const htmlTag = `
                    <style>
                      body {
                        margin: 0px;
                      }

                      img {
                        width: 100%;
                      }

                      p br{
                        display:none;
                      }

                      table, pre,div {
                        color: #575757; 
                        font-size: 1em;
                      }

                      .newPopupDiv {
                        height: calc(100% - 30px);
                        width: 100%;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-image: url(${encodeURI(imgUrl)});
                      }

                      #next_close {
                        margin-top: 9px;
                      }

                      div.popup {
                        clear: both;
                        position: relative;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        text-align: right;
                      }

                      div.popup .ct_bt01 {
                        margin-right:10px;
                        margin-left:10px;
                      }

                      @media(max-width: 736px) {
                        .mmimg_w {
                          width:100% !important;
                          height: calc(100% - 30px) !important;
                          top: 0 !important;
                          left: 0 !important;
                        }

                        .mmimg_w, .mmimg_in, .mmmimg{
                          width: 100% !important;
                          height: calc(100% - 30px) !important;
                        }

                        .mmmimg p, .mmmimg img {
                          width: 100% !important;
                          height: auto;
                        }
                      }
                  </style>
                  <div>
                    <div class="mmmimg newPopupDiv">
                      ${content==null?'':content}
                    </div> 
                    <div class="popup">
                      ${nextCloseYn=='Y'? `<input type="checkbox" id="next_close">${nextCloseDd} ${this.$t('nextCloseDdText')}`:''}
                      <input type="button" value="닫기" onClick="closeToday()" class="ct_bt01">
                    </div>  
                  </div> 
                  <script type="text/javascript">
                    function closeToday() {
                      ${nextCloseYn=='Y'? `var isNextClose = document.getElementById('next_close').checked;

                      if(isNextClose) {
                        var todayDate = new Date();
                        todayDate.setDate( todayDate.getDate() + ${nextCloseDd} );
                        document.cookie = name + "= Y" + "; path=/; expires=" + todayDate.toGMTString() + ";"
                      }
                      `:''}
                      window.close();
                    }
                  <\/script>`;
          win.document.write(htmlTag);
        }
      }
    }, 
    doLocationMapFocus(event, code) {
      if(event.keyCode == 13 && code != null && code != '') {
        this.openGardenModal(code);
      }
    },
    doModalBackKeyDown(e) {
      if(e.shiftKey && e.keyCode == 9){
        e.preventDefault();
        document.querySelector("#homepage_move").focus()
      }
    },
    doModalKeyDown(e) {
      if(!e.shiftKey && e.keyCode == 9){
        e.preventDefault();
        document.querySelector("#modalFirst").focus()
      }
    }
  },
}
</script>